import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Lenders, LenderDetail } from 'pages';

const LendersLayout = () => (
  <Switch>
    <Route exact path="/lenders" component={Lenders} />
    <Route path="/lenders/:id(\d+)" component={LenderDetail} />
    <Redirect to="/rates" />
  </Switch>
);

export default LendersLayout;
